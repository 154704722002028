<template>
  <div class="vx-col w-11/12 ml-auto mr-auto">
    <span>
      <b v-if="id != null">Edit {{ title }}</b>
      <b v-else>Create {{ title }}</b>
    </span>
    <br><br>
    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label class="vs-input--label">Master Type</label>
         <multiselect
          v-model="data.masterType.selected"
          :options="data.masterType.options"
          placeholder="Type to search"
          :max-height="125"
          v-validate="'required'"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >{{ props.option.Name }}</span
              >
            </div>
          </template>
        </multiselect>
        <span class="text-danger text-sm" v-show="errors.has('MasterType')">{{
          errors.first("MasterType")
        }}</span>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Length Counter"
          v-validate="'required'"
          v-model="data.lengthCounter"
          @keypress="isNumber($event)"
        />
        <span class="text-danger text-sm" v-show="errors.has('LengthCounter')">{{
          errors.first("LengthCounter")
        }}</span>
      </div>
    </div>

    <br>
    <div class="vx-col sm:w-6/6 w-full mb-2">
        <label class="vs-input--label">Format</label>
    </div>
    
    <div class="vx-col sm:w-12/12 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      <div class="vx-row mb-2">
        <div class="vx-col w-4/12">
            <div v-if="items[indextr].typeFormat.selected === 'Default'">
            <vs-input
                class="w-full"
                placeholder="Format"
                v-model="items[indextr].formatName"
                v-validate="'required'"
            />
            </div>
            <div v-if="items[indextr].typeFormat.selected === 'Date'">
            <multiselect
            v-model="items[indextr].formatDate.selected"
            :options="items[indextr].formatDate.options"        
            :max-height="125"
            :show-labels="false"
            label="Name"        
                placeholder="Format"        
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('FormatDate')"
                >{{ errors.first("FormatDate") }}</span
            >
            </div>
            <div v-if="items[indextr].typeFormat.selected === 'Code'">
            <multiselect
            v-model="items[indextr].formatCode.selected"
            :options="items[indextr].formatCode.options"        
            :max-height="125"
            :show-labels="false"
            label="Name"       
                placeholder="Format"         
            >
            <span slot="noResult">Oops! No data found</span>
            <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </span>
            </template>

            <template slot="option" slot-scope="props">
                <div class="option__desc">
                <span class="option__title"
                    >{{ props.option }}</span
                >
                </div>
            </template>
            </multiselect>
            <span
                class="text-danger text-sm"
                v-show="errors.has('FormatCode')"
                >{{ errors.first("FormatCode") }}</span
            >
            </div>
          </div>

          <div class="vx-col w-3/12">
              <vs-input
                class="w-full"
                placeholder="Separator"
                v-model="items[indextr].separator"
                v-validate="'required'"
              />
          </div>

          <div class="vx-col w-4/12">
              <multiselect
              v-model="items[indextr].typeFormat.selected"
              :options="items[indextr].typeFormat.options"
              :max-height="125"
              :show-labels="false"
              label="name"
              >
              <span slot="noResult">Oops! No data found</span>
              <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                  <span class="option__title"
                      >{{ props.option }}</span
                  >
                  </span>
              </template>

              <template slot="option" slot-scope="props">
                  <div class="option__desc">
                  <span class="option__title"
                      >{{ props.option }}</span
                  >
                  </div>
              </template>
              </multiselect>
              <span
                  class="text-danger text-sm"
                  v-show="errors.has('TypeFormat')"
                  >{{ errors.first("TypeFormat") }}</span
              >
          </div>

          <div class="vx-col w-1/12">
              
            <div class="vx-col w-full" style="text-align: center; margin: auto; margin-bottom: 0">
                <vs-button
                    v-if="items[indextr].status=='minus'"
                    style="display: table-cell;"
                    v-on:click="handleRemoveItem(indextr)"
                    color="danger"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-minus"
                ></vs-button>
                <vs-button
                    v-else
                    style="display: table-cell;"
                    v-on:click="handleAddItem()"
                    color="success"
                    type="filled"
                    icon-pack="feather"
                    icon="icon-plus"
                ></vs-button>
            </div>
          </div>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
        <vs-button
          class="ml-4 mt-2"
          type="border"
          color="danger"
          v-on:click="handleClose"
          >Close</vs-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    url: {
      type: String,
    },
    title: {
      type: String,
    },
  },

  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          id: null,
          lengthCounter: 1, 
          masterTypeId: null,
          masterType: {
            options: [],
            selected: null
          },
          formatID: [],
          separatorName: [],
          typeFormatName: [],
        },
        items: [{
            num: 0,
            status: "plus",
            formatDate:{
                options: ["yyyymmdd", "ddmmyyyy", "yymmdd", "ddmmyy", "yyyymm", "mmyyyy", "yyyy"],
                selected: "yyyymmdd",
            },
            formatCode:{
                options: ["Warehouse", "Territory", "Operating Unit"],
                selected: "Warehouse",
            },
            formatName: "",
            separator: "",
            typeFormat: {
              options: ["Default", "Date", "Code"],
              selected: "Default"
            },
        }],
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (this.data.lengthCounter < 1) {
          this.errors.add({
            field: "LengthCounter",
            msg: "The Length Counter field is required 1",
          });
          result = false;
        }

        if (!this.data.masterType.selected) {
          this.errors.add({
            field: "MasterType",
            msg: "The Master Type field is required",
          });
          result = false;
        }

        
        var _this = this
        
        this.items.forEach(function(element, index){
            _this.data.typeFormatName[index] = _this.items[index].typeFormat.selected
            if(_this.items[index].typeFormat.selected == 'Default'){
              _this.data.formatID[index] = _this.items[index].formatName
            }else if(_this.items[index].typeFormat.selected == 'Date'){
              _this.data.formatID[index] = _this.items[index].formatDate.selected
            }else if(_this.items[index].typeFormat.selected == 'Code'){
              _this.data.formatID[index] = _this.items[index].formatCode.selected
            }
            
            _this.data.separatorName[index] = _this.items[index].separator
            
        })

        if (result) {
          
          this.$vs.loading();
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }
        }
      });
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        document_master_type_id: this.data.masterType.selected.ID,
        length_counter: parseInt(this.data.lengthCounter),
        formation: this.data.formatID,
        separator: this.data.separatorName,
        type_formation: this.data.typeFormatName,
      };
    },
    postData() {
      this.$http
        .post(this.url, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "New " + this.title + " Created",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    putData() {
      this.$http
        .put(this.url + "/" + this.id, this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: this.title + " Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getData() {
      this.$vs.loading();
      if (this.id) {
      this.$http
        .get(this.url + "/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.data.ID = this.id;
            this.data.lengthCounter = resp.data[0].LengthCounter;
            if (resp.data[0].DocumentMasterTypeID) {
              this.setMasterTypeSelected(resp.data[0].DocumentMasterTypeID);
            }
            var _this = this
            this.items = [];
            var statusAdd;
            for(var k = 0; k < resp.data.length; k++){
              if(k == 0){
                  statusAdd = "plus"
              }else{
                  statusAdd = "minus"
              }

              _this.items.push({
                  status: statusAdd,
                  formatDate:{
                      options: ["yyyymmdd", "ddmmyyyy", "yymmdd", "ddmmyy", "yyyymm", "mmyyyy", "yyyy"],
                      selected: resp.data[k].DateFormation,
                  },
                  formatCode:{
                      options: ["Warehouse", "Territory", "Operating Unit"],
                      selected: resp.data[k].Formation,
                  },
                  formatName: resp.data[k].Formation,
                  separator: resp.data[k].Separator,
                  typeFormat: {
                    options: ["Default", "Date", "Code"],
                    selected: resp.data[k].TypeFormation
                  },
              })    
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
        
      }
    },
    getDataMasterType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/setting/master-type", {
          params: {
            order: "name",
            sort: "asc",
          }
        })
        .then((resp) => {
          if (resp.status == "success") {
            this.data.masterType.options = resp.data.records;
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setMasterTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/v1/setting/master-type/" + id).then((resp) => {
        if (resp.status == "success") {
          this.data.masterType.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    handleAddItem(){
      this.items.splice(this.items.length+1, 0, {
          num: 0,
          status: "minus",
          formatDate:{
              options: ["yyyymmdd", "ddmmyyyy", "yymmdd", "ddmmyy", "yyyymm", "mmyyyy", "yyyy"],
              selected: "yyyymmdd",
          },
          formatCode:{
              options: ["Warehouse", "Territory", "Operating Unit"],
              selected: "Warehouse",
          },
          formatName: "",
          separator: "",
          typeFormat: {
            options: ["Default", "Date", "Code"],
            selected: "Default"
          },
      })
      
    },
    handleRemoveItem(indextr){
      this.items.splice(indextr, 1)
    },
  },
  mounted() {
    this.getDataMasterType();
    if (this.id) {
      this.getData();
    }
  },
  computed: {},
  watch: {
    id(v) {
      this.getDataMasterType();
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
  },
};
</script>
<style >
</style>
